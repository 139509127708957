
.background {
  background-image: url("./img/tattoo jelly swiss switzerland mini Mini Micro male small tatoos new tatto persfect cheap tattoo.jpg");
 height: 1000px;
 width: 100%;
 background-repeat: no-repeat;
 background-color: black;
 position: fixed;
}
.text-naslov{
  position: relative;
  text-align: center;
  color:aliceblue;
  padding: 15px;
  color:aliceblue;
  opacity: 0.6;
  font-size: 71px;
}
.text-naslov1{
  position: relative;
  color:aliceblue;
  padding: 65px;
  top: 100px;
  opacity: 0.6;
  left:480px;
  font-size: 26px;
}
.footer{
  color: white;
    opacity: 0.6;
    right: 780px;
    font-size: 20px;
}
.info{
  font-size: 17px;
  position: relative;
  left: 540px;
  top: 250px;
}
.tit-2{
  color: white;
  position:relative;
  top: 90px;
  left: 580px;
  font-size: 18px;
  opacity: 0.6;
}
.wapp{
  position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
   
}

.mobile-res{
display: none;
}






@media screen and (max-width: 800px) {
 .mobile-res{
  display: block;
   color:white;
   position: relative;
   right: 500px;
   top: 300px;
  
   }
   .text-naslov{
    position: relative;
    right: 90px;
    font-size: 28px;
    padding-top: 50px;
   }
}
@media screen and (max-width: 870px) {
 .mobile-res{
  display: block;
   color:white;
   position: relative;
   right: 500px;
   top: 320px;
}
}

 .whatsapp-icon {
   margin-top: 16px;
 }

 /* for mobile */
 @media screen and (max-width: 767px) {
   .whatsapp-icon {
     margin-top: 10px;
   }

   .whatsapp_float {
     width: 40px;
     height: 40px;
     bottom: 20px;
     right: 10px;
     font-size: 22px;
   }
 }

